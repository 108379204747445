<template>
  <div class="page">
    <template v-if="isMobile">
      <template v-if="$route.meta.page=='empty'">
        <router-view name="mobile"/>
      </template>
      <template v-else>
        <m-header></m-header>
        <router-view name="mobile"/>
        <m-footer></m-footer>
      </template>
    </template>
    <template v-else>
      <pc-header></pc-header>
      <router-view name="pc"/>
      <pc-foot></pc-foot>
    </template>
    <router-view name="default"></router-view>
  </div>
</template>

<script>
import {terminal} from "@/utils";
import pcHeader from '@/pc/components/header'
import pcFoot from '@/pc/components/footer'
import mHeader from '@/mobile/components/header'
import mFooter from '@/mobile/components/footer'

export default {
  name: "layout-default",
  components: {pcHeader, pcFoot,mHeader,mFooter},
  data() {
    return {
      isMobile: terminal.mobile
    }
  }
}
</script>

<style scoped>
.page{
  height: 100%;
}
.pc-page{
  padding: 64px 0;
}
</style>