<template>
  <div style="height: 100%">
    <template v-if="isMobile">
      <router-view name="mobile"/>
    </template>
    <template v-else>
      <div class="head">
        <a class="hl"><img class="logo" :src="require('@/assets/pc/bttb_xh.png')" alt="">{{title}}</a>
        <div @click="$router.back()" class="btn-back">Back</div>
      </div>
      <router-view name="pc"/>
    </template>
    <router-view name="default"></router-view>
  </div>
</template>

<script>
import {terminal} from "@/utils";

export default {
  name: "layout-empty",
  data() {
    return {
      isMobile: terminal.mobile,
    }
  },
  computed:{
    title(){
      if(this.$route.meta){
        return this.$route.meta.title || ''
      }else{
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pc-page{
  padding-top: 64px;
}
.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: 9;
  .hl{
    font-size: 26px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    .logo{
      margin-right: 10px;
    }
  }
  .btn-back {
    display: inline-block;
    font-size: 18px;
    padding-right: 40px;
    background: url("@/assets/pc/jr_l.png") no-repeat;
    background-size: 28px 28px;
    background-position: right center;
    line-height: 28px;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
  }
}
</style>