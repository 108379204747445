<template>
  <el-dialog custom-class="dialog" width="580px" :visible="visible" :before-close="handleClose" append-to-body>
    <div slot="title" class="top">
      <img class="logo" :src="require('@/assets/pc/dl_tb.png')" alt="">
    </div>
    <div class="content">
      <el-form>
        <el-form-item>
          <el-input v-model="account" placeholder="User Name">
            <div slot="prefix" class="iconbox"><img class="icon" :src="require('@/assets/pc/dl_xtb_yh.png')"/></div>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="password" show-password placeholder="Password">
            <div slot="prefix" class="iconbox"><img class="icon" :src="require('@/assets/pc/dl_xtb_mm.png')"/></div>
          </el-input>
        </el-form-item>
        <div class="tipbox">
          <div class="tip">{{ text }}</div>
        </div>
        <el-form-item>
          <div @click="login" class="btn-login">LOGIN</div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {login} from '@/api/index'
import {setToken, setExpires, setUserInfo} from "@/utils/auth";

export default {
  name: "pc-login",
  props: ['visible'],
  data() {
    return {
      account: '',
      password: '',
      text: '',
    }
  },
  methods: {
    login() {
      let {account, password} = this
      if (!account) {
        this.text = 'Please enter your name'
        return
      }
      if (!password) {
        this.text = 'Please enter your password'
        return
      }
      login({account, password}).then(res => {
        let {code, errors, data} = res.data
        if (code == 0) {
          setToken(data.token)
          setExpires(data.expiresLong)
          setUserInfo(data.userName)
          this.handleClose()
          this.$emit('login')
          location.reload();
        } else {
          this.text = errors
        }
      })
    },
    handleClose() {
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .dialog {
  border-radius: 12px;

  .el-dialog__header {
    box-sizing: border-box;

    .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
    }
  }

  .el-dialog__body {
    padding: 30px 40px;
  }
}

.top {
  text-align: center;
  height: 120px;

  .logo {
    margin: -90px auto 0;
    width: 170px;
    height: 170px;
  }
}

.content {
  .iconbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  ::v-deep .el-input__inner {
    padding-left: 45px;
    height: 60px;
    line-height: 60px;
    border-radius: 12px;
    font-size: 16px;
  }

  .tipbox {
    min-height: 50px;
    padding-bottom: 10px;
    box-sizing: border-box;

    .tip {
      font-size: 16px;
      color: #ff0e0e;
      margin-left: 18px;
      padding-left: 30px;
      background: url("@/assets/pc/info_2.png") no-repeat;
      background-size: 16px 16px;
      background-position: 0 0;
      line-height: 16px;
    }
  }

  .btn-login {
    cursor: pointer;
    height: 60px;
    background: #00a786;
    color: #fff;
    text-align: center;
    line-height: 60px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50px;
  }
}
</style>