<template>
  <div class="footer">
    <a href="" class="copyright">Copyright © 2024 Zhiwen Liu - All Rights Reserved.</a>
  </div>
</template>

<script>
export default {
  name: "footer-index"
}
</script>

<style scoped lang="scss">
.footer {
  text-align: center;
  height: 80px;
  margin-top: -80px;
  .copyright{
    font-size: 24px;
    color: #585858;
    line-height: 80px;
  }
}
</style>