import request from "@/utils/request";

export function login(data) {
    return request({
        url: "/api/ml-adminuser/login",
        method: "post",
        data,
    });
}

export function getList(data) {
    return request({
        url: "/api/ml-musicscore/page",
        method: "post",
        data,
    });
}

export function guid(data) {
    return request({
        url: "/api/ml-musicscore/guid",
        method: "get",
        data,
    });
}


export function fileUpload(data) {
    return request({
        url: "/api/file-upload/uploadfile",
        method: "post",
        data,
        hideLoading:true,
        headers:{
            'Content-Type':'multipart/form-data'
        }
    });
}

export function add(data) {
    return request({
        url: "/api/ml-musicscore/add",
        method: "post",
        data,
    });
}

export function update(data) {
    return request({
        url: "/api/ml-musicscore/update",
        method: "post",
        data,
    });
}

export function getDetail(data) {
    return request({
        url: "/api/ml-musicscore/detail",
        method: "post",
        data,
    });
}

export function deleteWrok(id) {
    return request({
        url: "/api/ml-musicscore/delete/"+id,
        method: "post",
        // data,
    });
}

export function sendmail(data) {
    return request({
        url: "/api/contact/sendmail",
        method: "post",
        data,
    });
}
