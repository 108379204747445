<template>
  <div class="footer">
    <a href="" class="copyright">Copyright © 2024 Zhiwen Liu - All Rights Reserved.</a>
  </div>
</template>

<script>
export default {
  name: "footer-index"
}
</script>

<style scoped lang="scss">
.footer {
  text-align: center;
  height: 64px;
  margin-top: -64px;

  .copyright{
    font-size: 13px;
    color: #979797;
    line-height: 64px;
  }
}
</style>