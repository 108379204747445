<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="keepAlive" :key="key"></router-view>
    </keep-alive>
    <router-view v-if="!keepAlive" :key="key"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.path;
    },
    keepAlive() {
      return this.$route.meta.keepAlive;
    },
  },
}
</script>

<style lang="scss">
html,body{
  height: 100%;
}
#app{
  height: 100%;
  font-size: 16px;
}
</style>
