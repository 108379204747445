import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layouts/defalut";
import emptyLaout from "@/components/layouts/emptyLaout";
import {getExpires, getToken,} from "@/utils/auth";

Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/home',
        component: Layout,
        children: [{
            path: '/home',
            name: 'home',
            components: {
                mobile: () => import('@/mobile/home'),
                pc: () => import('@/pc/home'),
            }
        }]
    },
    {
        path: '/about',
        component: Layout,
        children: [{
            path: '/about',
            name: 'about',
            components: {
                mobile: () => import('@/mobile/about'),
                pc: () => import('@/pc/about'),
            }
        }]
    },
    {
        path: '/music',
        component: Layout,
        children: [{
            path: '/music',
            name: 'music',
            components: {
                mobile: () => import('@/mobile/music'),
                pc: () => import('@/pc/music'),
            }
        }, {
            path: 'detail',
            name: 'detail',
            meta: {activePath: '/music',page:'empty'},
            components: {
                mobile: () => import('@/mobile/music/detail'),
                pc: () => import('@/pc/music/detail'),
            }
        }]
    },
    {
        path: '/music',
        component: emptyLaout,
        children: [{
            path: 'score',
            name: 'score',
            meta: {title: 'Music Score'},
            components: {
                mobile: () => import('@/mobile/music/score'),
                pc: () => import('@/pc/music/score'),
            }
        }, {
            path: 'manage',
            name: 'manage',
            meta: {title: 'Manage works',needLogin:'true',keepAlive:true},
            components: {
                mobile: () => import('@/mobile/music'),
                pc: () => import('@/pc/music/manage'),
            }
        }, {
            path: 'edit',
            name: 'edit',
            meta: {title: 'EDIT WORKS',needLogin:'true'},
            components: {
                mobile: () => import('@/mobile/music'),
                pc: () => import('@/pc/music/edit'),
            }
        }]
    },
    {
        path: '/resume',
        component: Layout,
        children: [{
            path: '/resume',
            name: 'resume',
            components: {
                mobile: () => import('@/mobile/resume'),
                pc: () => import('@/pc/resume'),
            }
        }]
    },
    {
        path: '/contact',
        component: Layout,
        children: [{
            path: '/contact',
            name: 'contact',
            components: {
                mobile: () => import('@/mobile/contact'),
                pc: () => import('@/pc/contact'),
            }
        }]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savePosition) {
        if (savePosition) {
            return savePosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
})

router.beforeEach(async (to, from, next) => {
    document.title = 'Zhiwen Liu';
    if (!to.meta.needLogin) {
        next()
    } else {
        let token = getToken();
        let timestamp = (new Date()).getTime();
        let expires = getExpires() || 0;

        if (token && expires - timestamp > 0) {
            next()
        } else {
            console.log(123,    this)
        }
    }

});



export default router
