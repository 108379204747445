<template>
  <div class="header">
    <div class="navBar">
      <router-link :to="item.path" class="tab" v-for="item in pathList" :key="item.path"
                   :class="{active:item.path==activePath}">
        {{ item.pathName }}
      </router-link>
    </div>
    <div class="user">
      <img class="icon1" :src="require('@/assets/pc/cdtb_ren.png')" alt="">
      <!--未登录-->
      <span @click="loginVisible=true" v-if="!userName" class="text">LOGIN</span>
      <!--已登录-->
      <template v-else>
        <span class="text" title="">Hi,{{ userName }}</span>
        <span class="line">|</span>
        <img @click="logout" class="icon2" :src="require('@/assets/pc/cdtb_tc.png')" alt="">
      </template>
    </div>

    <!--登录-->
    <login :visible="loginVisible" @close="loginVisible=false" @login="getUser"></login>
  </div>
</template>

<script>
import login from '@/pc/login'
import {getUserInfo, removeToken, removeUserInfo} from "@/utils/auth";

export default {
  name: "header-index",
  components: {login},
  data() {
    return {
      userName: '',
      pathList: [{
        path: '/home',
        pathName: 'HOME'
      }, {
        path: '/about',
        pathName: 'ABOUT'
      }, {
        path: '/music',
        pathName: 'MUSIC'
      }, {
        path: '/resume',
        pathName: 'RESUME'
      }, {
        path: '/contact',
        pathName: 'CONTACT'
      }],
      loginVisible: false,
    }
  },
  mounted() {
    this.getUser()
  },
  computed: {
    activePath() {
      let route = this.$route
      if (route.meta) {
        return route.meta.activePath || route.path
      } else {
        return route.path
      }
    }
  },
  methods: {
    getUser(){
      let userName = getUserInfo('userInfo')
      this.userName = userName ? JSON.parse(userName) : ''
    },
    logout() {
      let t = this
      this.$confirm('Confirm Logout?', 'Prompt', {
        cancelButtonText:'cancel',
        confirmButtonText:'confirm'
      }).then(() => {
        removeUserInfo()
        removeToken()
        t.userName = ''
        this.$router.push('/home')
      }).catch(() => {
        // t.$message.warning(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  z-index: 9;

  .navBar {
    text-align: center;
    flex: 1;

    .tab {
      font-size: 15px;
      color: #fff;
      display: inline-block;
      margin: 0 40px;

      &.active {
        color: #00a786;
        position: relative;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background: #00a786;
          border-radius: 50%;
          position: absolute;
          bottom: -12px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

  }

  .user {
    padding: 0 15px;
    flex: 0 0 auto;

    img, span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
    }

    .icon1 {
      width: 15px;
      height: 16px;
    }

    .icon2 {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .text {
      font-size: 15px;
      color: #fff;
      max-width: 8em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    .line {
      color: #565453;
    }
  }
}
</style>