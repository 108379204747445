<template>
  <div>
    <div class="header">
      <div class="title">{{ show ? 'MENU' : pathName }}</div>
      <img v-if="show" @click="show=false" class="icon" :src="require('@/assets/mobile/cdtb_dx.png')"/>
      <img v-else @click="show=true" class="icon" :src="require('@/assets/mobile/cdtb_cd.png')"/>
    </div>
    <van-popup v-model="show" position="top" overlay-class="headPop">
      <router-link :to="item.path" class="tab" v-for="item in pathList" :key="item.path">
        <span class="text" :class="{active:activePath==item.path}">{{ item.pathName }}</span>
      </router-link>
    </van-popup>
  </div>

</template>

<script>export default {
  name: "header-index",
  data() {
    return {
      show: false,
      pathList: [{
        path: '/home',
        pathName: 'HOME'
      }, {
        path: '/about',
        pathName: 'ABOUT'
      }, {
        path: '/music',
        pathName: 'MUSIC'
      }, {
        path: '/resume',
        pathName: 'RESUME'
      }, {
        path: '/contact',
        pathName: 'CONTACT'
      }],
    }
  },
  computed: {
    activePath() {
      let route = this.$route
      if (route.meta) {
        return route.meta.activePath || route.path
      } else {
        return route.path
      }
    },
    pathName() {
      let obj = this.pathList.find(item => item.path == this.activePath)
      return obj ? obj.pathName : 'MENU'
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.header {
  height: 100px;
  background: #00a786;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  box-sizing: border-box;

  .title {
    font-size: 36px;
    color: #fff;
  }

  .icon {
    width: 42px;
    height: 42px;
  }
}

::v-deep {
  .headPop {
    &.van-overlay {
      top: 100px;
      background: rgba(0, 0, 0, .9);
    }
  }

  .van-popup--top {
    top: 100px;
  }

  .van-popup {
    background: transparent;

    .tab {
      display: block;
      margin: 0 44px;
      padding: 36px 14px;

      + .tab {
        border-top: 1px solid #333;
      }

      .text {
        color: #fff;
        font-size: 38px;
        padding-left: 30px;

        &.active {
          position: relative;

          &:before {
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #00a786;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>