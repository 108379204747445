const TokenKey = 'token'
const Expires = 'expires'
const UserInfo = 'userInfo'

//token
export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    localStorage.setItem(TokenKey,token)
}

export function removeToken() {
    localStorage.removeItem(TokenKey)
}

//expires
export function getExpires() {
    return localStorage.getItem(Expires)
}

export function setExpires(expires) {
    localStorage.setItem(Expires,expires)
}

export function removeExpires() {
    localStorage.removeItem(Expires)
}

//userInfo
export function getUserInfo() {
    return localStorage.getItem(UserInfo)
}

export function setUserInfo(userInfo) {
    localStorage.setItem(UserInfo,JSON.stringify(userInfo))
}

export function removeUserInfo() {
    localStorage.removeItem(UserInfo)
}
