import axios from 'axios'
import {Loading} from 'element-ui'
import {getToken, removeToken, removeExpires, removeUserInfo} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/api' : '/api', // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000, // request timeout
    // headers: {
    //     //配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
    //     'Content-Type': 'application/json;',
    // },
})

let load;
// request interceptor
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        if (!config.hideLoading) {
            load = Loading.service({
                lock: true,
                background: "rgba(0, 0, 0, 0.2)"
            });
        }
        config.headers['Content-Type'] = !config.headers['Content-Type'] ?'application/json' :'multipart/form-data'
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 设置响应拦截器
service.interceptors.response.use(response => {
    if (load) {
        load.close()
    }
    return response
}, error => {
    if (load) {
        load.close()
    }
    // 如果是正常接口，会走err，err.response.status值为401 进行跳转及删除token的操作
    if (error.response) {
        switch (error.response.status) {
            case 401:
                // 返回 401 清除token信息并跳转到登录页面
                removeToken()
                removeExpires()
                removeUserInfo()
                location.reload();
                return Promise.reject("登录已过期,请重新登录");
            case 403:
                return Promise.reject("你没权限查询该资源");
            case 404:
                return Promise.reject("你所访问的资源不存在");
            case 500:
                return Promise.reject("服务器出错啦");
        }
    }
    return Promise.reject(error)
})

export default service
